































































































import { defineComponent, computed, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import { getApiUrl } from '@/inc/app.config'
import axios from 'axios'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

export default defineComponent({
  name: 'my-meter-invoices',
  components: { FeedbackMessage },
  props: {
    invoiceData: {
      required: true,
      type: Object as () => Record<string, any>,
    },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { buspartner } = (ctx.attrs.billingData as unknown) as Record<
      string,
      string
    >
    const { $logger } = ctx.root
    const isLoading = ref([''])

    const getDownloadLink = async (ref: string) => {
      let link = ''
      isLoading.value.push(ref)
      await axios
        .get(
          `${getApiUrl()}/billing/pdf?Ean=${
            ctx.attrs.id
          }&RefDocNo=${ref.trim()}`,
          {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          }
        )
        .then(res => {
          link = window.URL.createObjectURL(res.request.response)
          isLoading.value = isLoading.value.filter(item => item !== ref)
        })
        .catch($logger.error)

      if (link) {
        const a = document.createElement('a')
        a.href = link
        a.download = `${ctx.attrs.id}-${ref.trim()}.pdf`
        a.click()
        window.URL.revokeObjectURL(link)
      }
    }

    const filteredInvoices = computed(() =>
      props.invoiceData.liste.filter(invoice => invoice.amount)
    )

    return {
      i18n,
      filteredInvoices,
      isLoading,
      getDownloadLink,
    }
  },
})
